import { Image } from 'assets/images'
import UserListItem from 'components/UserListItem'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileSelector } from 'store/profile/selectors'
import { profileActions } from 'store/profile/slice'
import { userSelector } from 'store/user/selectors'
import { LOADING_STATUSES } from 'utils/constants'
import { formatNumberSuffix } from 'utils/format'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

function ProfileRanks() {
  const dispatch = useDispatch()
  const {
    userRank,
    topRankedUsers,
    getTopRankedUsersStatus,
    getUserRankStatus,
  } = useSelector(profileSelector)
  const { userTotalCoins } = useSelector(userSelector)

  useEffect(() => {
    if (
      !topRankedUsers.length &&
      getTopRankedUsersStatus === LOADING_STATUSES.idle
    ) {
      dispatch(profileActions.getTopRankedUsers())
    }
    if (!userRank && getUserRankStatus === LOADING_STATUSES.idle) {
      dispatch(profileActions.getUserRank())
    }
  }, [])

  return (
    <S.ProfileRanksWrapper>
      <S.ProfileHeader>
        <S.UserImage src={Image.ranksCup} alt='avatar' />
        <S.PageTitle>Ranks</S.PageTitle>
        <S.UserInfo>
          <S.UserStatus>{formatNumberSuffix(userRank)} place</S.UserStatus>
          <S.UserBalance>
            <S.StyledImage src={Image.coin} alt='coin' />
            {formatCoin(userTotalCoins)}
          </S.UserBalance>
        </S.UserInfo>
      </S.ProfileHeader>
      <S.RanksFeed>
        {topRankedUsers.map((user) => (
          <UserListItem
            key={user._id}
            username={user.username}
            coins={user.coins}
            rank={user.rank}
            showFullCoins={user.rank < 4}
            showCoins
          />
        ))}
      </S.RanksFeed>
    </S.ProfileRanksWrapper>
  )
}

export default ProfileRanks

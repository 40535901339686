import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'
import { getTopRankedUsers, getUserRank } from 'store/profile/actions'

const initialState = {
  topRankedUsers: [],
  userRank: null,
  getTopRankedUsersStatus: LOADING_STATUSES.idle,
  getUserRankStatus: LOADING_STATUSES.idle,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getTopRankedUsers.pending, (state) => {
      state.getTopRankedUsersStatus = LOADING_STATUSES.pending
    })
    addCase(getTopRankedUsers.fulfilled, (state, { payload }) => {
      state.topRankedUsers = payload
      state.getTopRankedUsersStatus = LOADING_STATUSES.succeeded
    })
    addCase(getTopRankedUsers.rejected, (state) => {
      state.getTopRankedUsersStatus = LOADING_STATUSES.failed
    })

    addCase(getUserRank.pending, (state) => {
      state.getUserRankStatus = LOADING_STATUSES.pending
    })
    addCase(getUserRank.fulfilled, (state, { payload }) => {
      state.userRank = payload
      state.getUserRankStatus = LOADING_STATUSES.succeeded
    })
    addCase(getUserRank.rejected, (state) => {
      state.getUserRankStatus = LOADING_STATUSES.failed
    })
  },
})

export const profileReducer = profileSlice.reducer

export const profileActions = {
  getTopRankedUsers,
  getUserRank,
  ...profileSlice.actions,
}

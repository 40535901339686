import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const EmptyLotteriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const EmptyLotteriesImage = styled.img`
  min-height: 60px;
  width: 60px;
`

export const EmptyLotteriesTitle = styled.p`
  font-size: 14px;
`

export const EmptyLotteriesDescription = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${COLORS.gray};
`

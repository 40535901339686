import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const LotteryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  justify-content: center;
  align-items: center;
`
export const LotteryHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`

export const LotteryImageWrapper = styled.div`
  min-height: 90px;
  width: 120px;
`

export const TicketImage = styled.img``

export const LotteryTitleWrapper = styled.div`
  color: ${COLORS.white};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const LotteryTitle = styled.p`
  font-size: 14px;
`

export const LotteryPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.gray_3B};
  padding: 4px 6px;
  border-radius: 4px;
  gap: 4px;
`

export const TelegramStarImage = styled.img`
  width: 16px;
  height: 16px;
  margin-bottom: 3px;
`

export const LotteryDescription = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${COLORS.gray};
`

export const LotteriesCountNumber = styled.p`
  color: ${COLORS.white};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`

export const LotteriesProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
`

export const LotteriesCountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
`

export const LotteriesCountButton = styled.button`
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.blue_00};
  color: white;
  font-size: 16px;
`

export const LotteriesCount = styled.input`
  width: 100%;
`

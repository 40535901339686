import styled, { keyframes } from 'styled-components'

export const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 64px;
  width: 64px;
`

export const LoadingCircle = styled.div`
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  animation: ${bounce} 1s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`

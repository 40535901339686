import { overflowScroll } from 'common/SharedStyles/styled'
import { styled } from 'styled-components'
import { COLORS } from 'utils/constants'

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 196px);
  ${overflowScroll}
`

export const ProfileFeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 32px;
`

export const ProfileSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1;
`

export const UserImage = styled.img`
  max-width: 56px;
  max-height: 56px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${COLORS.white};
  font-size: 14px;
`
export const UserStatus = styled.img`
  width: 16px;
  height: 16px;
`

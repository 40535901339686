import { Image } from 'assets/images'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/user/selectors'
import * as S from './styled'

function ProfileCards() {
  const { openedCards } = useSelector(userSelector)

  return (
    <S.ProfileCardsWrapper>
      <S.ProfileHeader>
        <S.UserImage src={Image.cards} alt='avatar' />
        <S.PageTitle>Cards</S.PageTitle>
        <S.UserInfo>
          <S.UserStatus>
            Cards: <S.Statistic>{openedCards.length}/40</S.Statistic>
          </S.UserStatus>
        </S.UserInfo>
      </S.ProfileHeader>
      <S.Grid>
        {openedCards.map((card) => (
          <S.CardContainer key={card.cardId}>
            <S.ContentWrapper>
              <S.ContentImageWrapper>
                <S.ContentImage src={card.cardDetails.imageUrl} />
              </S.ContentImageWrapper>
            </S.ContentWrapper>
            <S.TitleWrapper>{card.cardDetails.name}</S.TitleWrapper>
          </S.CardContainer>
        ))}
      </S.Grid>
    </S.ProfileCardsWrapper>
  )
}

export default ProfileCards

import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const GameItem = styled.div`
  color: ${COLORS.white_f8};
  background-color: ${COLORS.black_28};
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
`

export const GameImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`

export const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

export const GameDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
`

export const GameRewardsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const GameRewardCoin = styled.img`
  width: 16px;
  height: 16px;
`

export const GameReward = styled.p`
  font-size: 12px;
  font-weight: 400;
`

import { useEffect } from 'react'

export const useOutsideClick = (ref, handleOnClickOutside) => {
  const listener = (event) => {
    event.stopPropagation()
    if (!ref.current || ref.current.contains(event.target)) {
      return
    }
    handleOnClickOutside(event)
  }

  useEffect(() => {
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handleOnClickOutside])
}

import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const FriendsView = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`

export const FriendsListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`

export const FriendsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FriendsListHeaderLeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const HeaderTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
  span {
    color: ${COLORS.blue};
  }
`

export const HeaderSubtitle = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: ${COLORS.white_f8};
  display: flex;
  align-items: center;
`

export const FarmIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const FriendsCount = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${COLORS.white_f8};
  margin-block: 24px 16px;

  span {
    color: ${COLORS.blue};
  }
`

export const FriendsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-bottom: 60px;
  ${overflowScroll}
`

export const Friend = styled.div`
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: ${COLORS.black_28};
  border-radius: 6px;
  border: 1px solid ${COLORS.gray_3B};
`

export const FriendInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FriendAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`

export const FriendName = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
`

export const FriendPoints = styled.div`
  display: flex;
  align-items: center;
`

export const FriendPointsIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const FriendPointsText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
  margin-right: 8px;
`

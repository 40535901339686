import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const TicketWrapper = styled.div`
  display: grid;
  background-color: ${COLORS.black_28};
  padding: 14px;
  border-radius: 8px;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
`

export const TicketItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const TicketImage = styled.img`
  width: 38px;
  border-radius: 2px;

  @media (max-width: 480px) {
    width: 32px;
  }

  @media (max-width: 350px) {
    width: 28px;
  }
`

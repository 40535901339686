import { Image } from 'assets/images'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const CardContainer = styled.div`
  background-image: url(${Image.card});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 19/17;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ContentWrapper = styled.div`
  min-height: calc(80% - 20px);
  padding-inline: 8px;
  padding-top: 8px;
  max-width: 100%;
  position: relative;
  height: min-content;
`

export const ContentImageWrapper = styled.div`
  max-height: calc(100% - 8px);
  overflow: hidden;
  border-radius: 6px;
`

export const ContentImage = styled.img`
  object-fit: cover;
  width: 100%;
  filter: blur(14px);
`

export const AmountContainer = styled.div`
  max-width: 112px;
  max-height: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${COLORS.black_28};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const QRWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0 8px;
  @media (max-height: 570px), (max-width: 360px) {
    padding: 6px 0;
  }
`

export const QRImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

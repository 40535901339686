import { Image } from 'assets/images'
import Button from 'common/Button'
import { LOTTERY_PRICE } from 'utils/constants'
import * as S from './styled'

function BuyLotteryContent({
  buyLotteryTicket,
  count,
  setCount,
  increment,
  decrement,
}) {
  return (
    <S.LotteryContainer>
      <S.LotteryHeadingWrapper>
        <S.LotteryImageWrapper>
          <S.TicketImage src={Image.ticket} alt='ticket' width={120} />
        </S.LotteryImageWrapper>
        <S.LotteryTitleWrapper>
          <S.LotteryTitle>Lottery Ticket</S.LotteryTitle>
          <S.LotteryPrice>
            <S.TelegramStarImage src={Image.telegramStar} alt='star' />
            {count * LOTTERY_PRICE}
          </S.LotteryPrice>
        </S.LotteryTitleWrapper>
      </S.LotteryHeadingWrapper>
      <S.LotteryDescription>
        Buy a Lottery ticket with Telegram Stars 🌟 for your chance to win
        exciting rewards in our magical Christmas raffle!
      </S.LotteryDescription>
      <S.LotteriesCountWrapper>
        <S.LotteriesCountNumber>{count}</S.LotteriesCountNumber>
        <S.LotteriesProgressBarWrapper>
          <Button
            text='-'
            onClick={decrement}
            maxHeight='20px'
            maxWidth='40px'
            isDisabled={count === 1}
          />
          <S.LotteriesCount
            type='range'
            value={count}
            min={1}
            max={100}
            onChange={(e) => setCount(Number(e.target.value))}
          />
          <Button
            text='+'
            onClick={increment}
            maxHeight='20px'
            maxWidth='40px'
            isDisabled={count === 100}
          />
        </S.LotteriesProgressBarWrapper>
      </S.LotteriesCountWrapper>
      <Button
        text='Buy'
        maxHeight='42px'
        maxWidth='100%'
        onClick={buyLotteryTicket}
      />
    </S.LotteryContainer>
  )
}

export default BuyLotteryContent

import Button from 'common/Button'
import CoinImage from 'common/CoinImage'
import SingleText from 'common/Input'
import TaskIcon from 'common/TaskIcon'
import { getCardLabel } from 'utils/card'
import { TASK_TYPES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import CongratulationsModal from './CongratulationsModal'
import * as S from './styled'

function Task({
  taskType,
  cardsCount,
  friendsCount,
  reward,
  description,
  text,
  icon,
  disabled,
  isOpen,
  onClose,
  onClick,
  special,
}) {
  return (
    <S.SingleTask special={special}>
      <S.TaskInfo>
        <TaskIcon icon={icon ?? 'voyage'} />
        <S.TaskText>
          <SingleText>
            {taskType === TASK_TYPES.daily
              ? description
              : `Invite ${friendsCount} friend and receive:`}
          </SingleText>
          <S.TaskPoints>
            <S.TaskCardInvitationRewardCard>
              {cardsCount} {getCardLabel(cardsCount)} and
            </S.TaskCardInvitationRewardCard>
            <CoinImage />
            <SingleText>{formatCoin(reward)}</SingleText>
          </S.TaskPoints>
        </S.TaskText>
        <CongratulationsModal
          cardsCount={cardsCount}
          isOpen={isOpen}
          onClose={onClose}
          reward={reward}
        />
      </S.TaskInfo>
      <Button
        maxWidth='65px'
        padding='10px 14px'
        onClick={onClick}
        text={text}
        disabled={disabled}
      />
    </S.SingleTask>
  )
}

export default Task

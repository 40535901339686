import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'
import { registerUser, getMe, registerReferral } from 'store/user/actions'
import { Image } from 'assets/images'
import { getUserStatus } from 'utils/card'

const initialState = {
  user: {
    id: 1,
    telegramId: 1,
    name: 'Jon',
    avatar: Image.avatar,
  },
  invitedFriendsCount: 0,
  userTotalCoins: 0,
  userFarmedCoins: 0,
  openedCards: [],
  userStatus: null,
  registerUserStatus: LOADING_STATUSES.idle,
  getUserStatus: LOADING_STATUSES.idle,
}

const userSlice = createSlice({
  name: 'career',
  initialState,
  reducers: {
    addTotalCoins(state, action) {
      state.userTotalCoins += action.payload
    },
    addFarmedCoins(state, action) {
      state.userTotalCoins += action.payload
      state.userFarmedCoins += action.payload
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(registerUser.pending, (state) => {
      state.registerUserStatus = LOADING_STATUSES.pending
    })
    addCase(registerUser.fulfilled, (state, { payload }) => {
      state.user.id = payload._id
      state.user.telegramId = payload.telegramId
      state.user.name = payload.username
      state.userTotalCoins = payload.coins
      state.userFarmedCoins = payload.farmedCoins
      state.openedCards = payload.openedCards
      state.userStatus = getUserStatus(payload.openedCards.length)
      state.invitedFriendsCount = payload.invitedFriends
      state.invitedFriends = payload.invitedFriends
      state.registerUserStatus = LOADING_STATUSES.succeeded
    })
    addCase(registerUser.rejected, (state) => {
      state.registerUserStatus = LOADING_STATUSES.failed
    })
    addCase(getMe.pending, (state) => {
      state.getUserStatus = LOADING_STATUSES.pending
    })
    addCase(getMe.fulfilled, (state, { payload }) => {
      state.user.id = payload._id
      state.user.telegramId = payload.telegramId
      state.user.name = payload.username
      state.userTotalCoins = payload.coins
      state.userFarmedCoins = payload.farmedCoins
      state.openedCards = payload.openedCards
      state.userStatus = getUserStatus(payload.openedCards.length)
      state.invitedFriendsCount = payload.invitedFriends
      state.invitedFriends = payload.invitedFriends
      state.getUserStatus = LOADING_STATUSES.succeeded
    })
    addCase(getMe.rejected, (state) => {
      state.getUserStatus = LOADING_STATUSES.failed
    })
    addCase(registerReferral.pending, (state) => {
      state.registerUserStatus = LOADING_STATUSES.pending
    })
    addCase(registerReferral.fulfilled, (state, { payload }) => {
      state.user.id = payload._id
      state.user.telegramId = payload.telegramId
      state.user.name = payload.username
      state.userTotalCoins = payload.coins
      state.userFarmedCoins = payload.farmedCoins
      state.openedCards = payload.openedCards
      state.userStatus = getUserStatus(payload.openedCards.length)
      state.invitedFriendsCount = payload.invitedFriends
      state.invitedFriends = payload.invitedFriends
      state.registerUserStatus = LOADING_STATUSES.succeeded
    })
    addCase(registerReferral.rejected, (state) => {
      state.registerUserStatus = LOADING_STATUSES.failed
    })
  },
})

export const userReducer = userSlice.reducer

export const userActions = {
  ...userSlice.actions,
  registerReferral,
  registerUser,
  getMe,
}

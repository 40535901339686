export const formatCoin = (coin, dotted) => {
  // TODO move to the format.js file
  const formatWithDots = (number) => {
    return number.toLocaleString('de-DE')
  }

  if (dotted) {
    return formatWithDots(coin)
  }

  if (coin < 1000) {
    return coin.toFixed(3).toString()
  }
  if (coin < 1_000_000) {
    // Less than a million
    return `${(coin / 1000).toFixed(1)} k`
  }
  if (coin < 1_000_000_000) {
    // Less than a billion
    return `${(coin / 1_000_000).toFixed(1)} m`
  }
  // One billion or more
  return `${(coin / 1_000_000_000).toFixed(1)} b`
}

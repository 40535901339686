import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  margin-block: 10px;
  background-color: transparent;
  color: ${COLORS.white};
`

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

export const RightPartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const NoCoin = styled.span`
  padding-left: 10px;
`

export const UserInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const ButtonText = styled.p`
  max-width: ${({ maxWidth }) => `${maxWidth}px` ?? '90px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const UserStatus = styled.img`
  width: 14px;
  height: 14px;
`

import React from 'react'
import Button from 'common/Button'
import * as S from './styled'

export function InviteFriends({ inviteFriend, hasFriends }) {
  return (
    <S.InviteButtonWrapper hasFriends={hasFriends}>
      <Button text='Invite friends' maxWidth='100%' onClick={inviteFriend} />
    </S.InviteButtonWrapper>
  )
}

import { GIF } from 'assets/gifs'
import VoyageIcon from 'assets/icons/VoyageIcon'
import Button from 'common/Button'
import { Dialog } from 'common/Dialog'
import Skeleton from 'common/Skeleton'
import BuyLotteryContent from 'components/BuyLotteryContent'
import BubbleAnimation from 'components/FarmBubbleAnimation'
import { LotteryCongratulationsModal } from 'components/LotteryCongratulationsModal'
import { TotalERVCoin } from 'components/TotalERVCoin'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { farmSelector } from 'store/farm/selectors'
import { farmActions } from 'store/farm/slice'
import { shopSelector } from 'store/shop/selectors'
import { shopActions } from 'store/shop/slice'
import { userSelector } from 'store/user/selectors'
import { LOADING_STATUSES, TASK_BUTTONS_STATES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import { formatTime } from 'utils/format-time'
import * as S from './styled'

function FarmForm() {
  const { coinsPerSecond, timeLeft, farmedCoins, getFarmingStatus } =
    useSelector(farmSelector)
  const {
    boughtLotteries,
    latestInvoiceLink,
    getLatestLotteryTicketsStatus,
    getInvoiceLinkStatus,
  } = useSelector(shopSelector)

  const { userFarmedCoins, userTotalCoins } = useSelector(userSelector)
  const [loading, setLoading] = useState(true)
  const [openLotteryModal, setOpenLotteryModal] = useState(false)
  const [remainingTime, setRemainingTime] = useState(timeLeft)
  const [coins, setCoins] = useState(farmedCoins)
  const [openCongratsModal, setOpenCongratsModal] = useState(false)
  const [lotteriesCount, setLotteriesCount] = useState(1)

  const dispatch = useDispatch()

  const increment = useCallback(() => {
    if (lotteriesCount < 100) {
      setLotteriesCount((prevCount) => prevCount + 1)
    }
  }, [lotteriesCount])

  const decrement = useCallback(() => {
    if (lotteriesCount > 1) {
      setLotteriesCount((prevCount) => prevCount - 1)
    }
  }, [lotteriesCount])

  const getLottery = useCallback(() => {
    setOpenLotteryModal(true)
  }, [])

  const buyLotteryTicket = useCallback(() => {
    dispatch(
      shopActions.getInvoiceLink({ id: 'lottery', count: lotteriesCount })
    )
  }, [dispatch, lotteriesCount])

  const startFarming = useCallback(() => {
    dispatch(farmActions.startFarming({ timeLeft }))
  }, [dispatch, timeLeft])

  useEffect(() => {
    setLoading(true)

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    if (timeLeft) {
      dispatch(farmActions.checkFarmingStatus())
    }

    return () => {
      clearTimeout(timeoutId)
      dispatch(shopActions.resetBoughtLotteries())
    }
  }, [])

  useEffect(() => {
    if (getFarmingStatus === LOADING_STATUSES.succeeded) {
      setCoins(farmedCoins)
    }
  }, [getFarmingStatus])

  useEffect(() => {
    if (
      getInvoiceLinkStatus === LOADING_STATUSES.succeeded &&
      latestInvoiceLink
    ) {
      try {
        window.Telegram.WebApp.openInvoice(latestInvoiceLink)
      } catch (e) {
        console.warn(e.message)
      }
      const invoiceClosedHandler = (event) => {
        if (event && event.status === 'paid') {
          dispatch(shopActions.getLatestLotteryTickets(lotteriesCount))
          setLotteriesCount(1)
        }
        window.Telegram.WebApp.offEvent('invoiceClosed', invoiceClosedHandler)
      }
      window.Telegram.WebApp.onEvent('invoiceClosed', invoiceClosedHandler)
      setOpenLotteryModal(false)
    }
  }, [getInvoiceLinkStatus])

  useEffect(() => {
    setRemainingTime(timeLeft)

    if (timeLeft) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(timer)
            dispatch(farmActions.checkFarmingStatus())
            return 0
          }

          setCoins((prev) => prev + coinsPerSecond)
          return prevTime - 1000
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [timeLeft])

  useEffect(() => {
    if (getLatestLotteryTicketsStatus === LOADING_STATUSES.succeeded) {
      setOpenCongratsModal(true)
    }
  }, [getLatestLotteryTicketsStatus])

  return (
    <S.FarmView>
      <S.GiftWrapper onClick={getLottery}>
        <S.StyledImage src={GIF.gift} width={110} height={110} alt='Gift' />
      </S.GiftWrapper>
      <S.SantaWrapper onClick={getLottery}>
        <S.StyledImage src={GIF.santa} width={80} height={80} alt='Santa' />
      </S.SantaWrapper>
      <S.TotalCoinsWrapper>
        <TotalERVCoin totalCoin={formatCoin(userTotalCoins)} />
      </S.TotalCoinsWrapper>
      <BubbleAnimation isStartAnimation={!!timeLeft} />
      <S.TotalCoinInfo>
        <S.CoinLabel>Totally farmed:</S.CoinLabel>
        <S.CoinValue>
          <VoyageIcon />
          ERV: {formatCoin(userFarmedCoins)}
        </S.CoinValue>
      </S.TotalCoinInfo>
      {loading ? (
        <S.FormStartButton>
          <Skeleton.Line width='100%' height='36px' />
        </S.FormStartButton>
      ) : !timeLeft ? (
        <S.FormStartButton>
          <Button
            text={
              timeLeft === 0
                ? TASK_BUTTONS_STATES.collect
                : TASK_BUTTONS_STATES.start
            }
            onClick={startFarming}
            maxWidth='100%'
          />
        </S.FormStartButton>
      ) : (
        <S.FormFarmingInformation>
          <S.Timer>
            Farming: <VoyageIcon />
            {Number(formatCoin(Number(coins))).toFixed(3)}
          </S.Timer>
          <S.Timer>{formatTime(remainingTime)}</S.Timer>
        </S.FormFarmingInformation>
      )}
      <Dialog
        isOpen={openLotteryModal}
        closeDialog={() => setOpenLotteryModal(false)}
      >
        <BuyLotteryContent
          buyLotteryTicket={buyLotteryTicket}
          increment={increment}
          decrement={decrement}
          setCount={setLotteriesCount}
          count={lotteriesCount}
        />
      </Dialog>
      <LotteryCongratulationsModal
        isOpen={openCongratsModal}
        onClose={() => setOpenCongratsModal(false)}
        lotteries={boughtLotteries}
      />
    </S.FarmView>
  )
}

export default FarmForm

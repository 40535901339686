import TicketIcon from 'assets/icons/TicketIcon'
import { TotalCards } from 'assets/icons/TotalCards'
import { TotalCoin } from 'assets/icons/TotalCoin'
import { TotalFarm } from 'assets/icons/TotalFarm'
import { TotalFriends } from 'assets/icons/TotalFriends'
import { Image } from 'assets/images'
import Button from 'common/Button'
import { ButtonText, UserInfo, UserStatus } from 'components/NavBar/styled'
import { COLORS } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function PersonalDetailsContent({
  user,
  userStatus,
  friendsCount,
  lotteries,
  userFarmedCoins,
  userTotalCoins,
  userTotalCards,
  openLotteries,
}) {
  const personalAnalytics = [
    {
      title: 'Total coins',
      value: formatCoin(userTotalCoins),
      icon: TotalCoin,
      isMoney: true,
    },
    {
      title: 'Lotteries',
      value: lotteries,
      icon: TicketIcon,
      isMoney: false,
      onClick: openLotteries,
    },
    {
      title: 'Total friends',
      value: friendsCount,
      icon: TotalFriends,
      isMoney: false,
    },

    {
      title: 'Totally farmed',
      value: formatCoin(userFarmedCoins),
      icon: TotalFarm,
      isMoney: true,
    },
    {
      title: 'Total cards',
      value: userTotalCards,
      icon: TotalCards,
      isMoney: false,
    },
  ]

  return (
    <S.PersonalDetailsDialogContent>
      <Button
        text={
          <UserInfo>
            <ButtonText maxWidth='80'>{user?.name}</ButtonText>
            <UserStatus src={userStatus.image} alt={userStatus.title} />
          </UserInfo>
        }
        leftIcon={<S.Icon src={user?.avatar} />}
        bgColor={COLORS.black_28}
        borderColor={COLORS.gray_3B}
        padding='8px 8px 8px 6px'
      />
      <S.PersonalDetailsContainer>
        {personalAnalytics.map((item) => (
          <S.AnalyticsItem
            key={item.title}
            onClick={item.onClick || (() => {})}
          >
            <S.IconWrapper>
              <S.IconArea>
                <item.icon />
              </S.IconArea>
              <S.Title>{item.title}</S.Title>
            </S.IconWrapper>
            <S.Value>
              {item.value}
              {item.isMoney ? (
                <S.CoinIcon src={Image.coin} />
              ) : (
                <S.RightSpace />
              )}
            </S.Value>
          </S.AnalyticsItem>
        ))}
      </S.PersonalDetailsContainer>
    </S.PersonalDetailsDialogContent>
  )
}

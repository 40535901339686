import { overflowScroll } from 'common/SharedStyles/styled'
import { styled } from 'styled-components'
import { COLORS } from 'utils/constants'

export const ProfileCardsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
  ${overflowScroll}
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const UserImage = styled.img`
  max-width: 56px;
  max-height: 56px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PageTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.white};
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: ${COLORS.white};
  font-size: 14px;
`
export const UserStatus = styled.p`
  color: ${COLORS.white};
  font-size: 12px;
`

export const Statistic = styled.span`
  color: ${COLORS.blue};
`

export const UserBalance = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.white};
  font-size: 12px;
  gap: 4px;
`

export const StyledImage = styled.img`
  width: 16px;
  height: 16px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  max-width: 100%;
`

export const CardContainer = styled.div`
  background-color: ${COLORS.black_28};
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 6/5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
`

export const ContentWrapper = styled.div`
  height: 100%;
  max-height: calc(80% - 8px);
  padding-inline: 8px;
  padding-top: 8px;
  max-width: 100%;
  position: relative;
  height: min-content;
`

export const ContentImageWrapper = styled.div`
  max-height: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 6px;
`

export const ContentImage = styled.img`
  object-fit: cover;
  width: 100%;
`

export const AmountContainer = styled.div`
  max-width: 112px;
  max-height: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${COLORS.black_28};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const TitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 20%;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${COLORS.white};
`

export const QRImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

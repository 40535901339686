import { shareURL } from '@telegram-apps/sdk-react'
import { Dialog } from 'common/Dialog'
import { InviteFriendDialogContent } from 'components/DialogsContent/InviteFrientDialogContent'
import { FriendsList } from 'components/FriendsList'
import { FriendsView } from 'components/FriendsList/styled'
import { FriendsNotExistScreen } from 'components/FriendsNotExistScreen'
import { InviteFriends } from 'components/InviteFriends'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { friendsCollect, getFriends } from 'store/friends/actions'
import { friendsSelector } from 'store/friends/selectors'
import { userSelector } from 'store/user/selectors'
import { userActions } from 'store/user/slice'
import { LOADING_STATUSES } from 'utils/constants'

function Friends() {
  const dispatch = useDispatch()
  const { friendsCount, friends, collectFriendsListStatus } =
    useSelector(friendsSelector)
  const { userTotalCoins } = useSelector(userSelector)
  const { user } = useSelector(userSelector)
  const [loading, setIsLoading] = useState(false)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const inviteFriend = () => {
    setIsDialogOpen(true)
  }

  const handleSendInTelegram = () => {
    shareURL(
      `https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}/?start=${user?.telegramId}`,
      'Join me on Voyager and start collecting crypto rewards! 🪙💰 Track your earnings, get bonus coins, and level up your crypto game. Let’s grow our coin collection together!🚀✨'
    )
  }

  const onCopyLinkClick = () => {
    setIsLoading(true)
    const link = `https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}/?start=${user?.telegramId}`
    const textToCopy = `${link}\n\nJoin me on Voyager and start collecting crypto rewards! 🪙💰 Track your earnings, get bonus coins, and level up your crypto game. Let’s grow our coin collection together!🚀✨`

    navigator.clipboard.writeText(textToCopy)

    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }

  const onClaimClick = useCallback(() => {
    dispatch(friendsCollect())
  }, [friendsCollect])

  useEffect(() => {
    dispatch(getFriends())
  }, [])

  useEffect(() => {
    if (collectFriendsListStatus === LOADING_STATUSES.succeeded) {
      const referralsProfit = friends.reduce((elem, acc) => elem + acc.coins, 0)
      dispatch(getFriends())
      dispatch(userActions.addTotalCoins(referralsProfit))
    }
  }, [collectFriendsListStatus])

  return (
    <FriendsView>
      {friendsCount > 0 ? (
        <FriendsList
          friends={friends}
          friendsCount={friendsCount}
          farmedCoins={userTotalCoins}
          onClaimClick={onClaimClick}
        />
      ) : (
        <FriendsNotExistScreen />
      )}
      <InviteFriends
        inviteFriend={inviteFriend}
        hasFriends={friendsCount > 0}
      />
      <Dialog
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        title='Invite a friend'
      >
        <InviteFriendDialogContent
          user={user}
          isLoading={loading}
          handleSendInTelegram={handleSendInTelegram}
          onCopyLinkClick={onCopyLinkClick}
        />
      </Dialog>
    </FriendsView>
  )
}

export default Friends

import React from 'react'
import { COLORS } from 'utils/constants'

function CardsIcon({ color = COLORS.white }) {
  return (
    <svg
      width='null'
      height='null'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.0183 9.43325L15.5462 10.4979C15.6182 10.6461 15.8102 10.7882 15.9722 10.8154L16.9291 10.9757C17.541 11.0786 17.685 11.5262 17.244 11.9677L16.5001 12.7178C16.3741 12.8448 16.3051 13.0898 16.3441 13.2652L16.5571 14.1937C16.7251 14.9287 16.3381 15.213 15.6932 14.8289L14.7963 14.2936C14.6343 14.1968 14.3674 14.1968 14.2024 14.2936L13.3055 14.8289C12.6636 15.213 12.2736 14.9257 12.4416 14.1937L12.6546 13.2652C12.6935 13.0898 12.6246 12.8448 12.4986 12.7178L11.7547 11.9677C11.3167 11.5262 11.4577 11.0786 12.0696 10.9757L13.0265 10.8154C13.1855 10.7882 13.3775 10.6461 13.4495 10.4979L13.9774 9.43325C14.2654 8.85558 14.7333 8.85558 15.0183 9.43325Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 17V20.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 3.5V7'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 8.87895C21.9331 7.33687 21.7456 6.33298 21.2203 5.53884C20.9181 5.08196 20.5428 4.68459 20.1112 4.36468C18.9447 3.5 17.299 3.5 14.0078 3.5H9.993C6.70173 3.5 5.05609 3.5 3.88957 4.36468C3.458 4.68459 3.08262 5.08196 2.78042 5.53884C2.25521 6.33289 2.06771 7.33665 2.00078 8.87843C1.98933 9.14208 2.21643 9.34375 2.46526 9.34375C3.85104 9.34375 4.97444 10.533 4.97444 12C4.97444 13.467 3.85104 14.6562 2.46526 14.6562C2.21643 14.6562 1.98933 14.8579 2.00078 15.1216C2.06771 16.6634 2.25521 17.6671 2.78042 18.4612C3.08262 18.918 3.458 19.3154 3.88957 19.6353C5.05609 20.5 6.70173 20.5 9.99301 20.5H14.0078C17.299 20.5 18.9447 20.5 20.1112 19.6353C20.5428 19.3154 20.9181 18.918 21.2203 18.4612C21.7456 17.667 21.9331 16.6631 22 15.1211V8.87895Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CardsIcon

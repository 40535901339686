import { ProfileWrapper } from 'components/ProfileFeed/styled'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/constants'

function Profile() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (location.pathname !== ROUTES.profile) {
      window.Telegram.WebApp.BackButton.show()
    } else {
      window.Telegram.WebApp.BackButton.hide()
    }
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(ROUTES.profile)
    })

    return () => {
      window.Telegram.WebApp.BackButton.hide()
    }
  }, [location.pathname])

  return (
    <ProfileWrapper>
      <Outlet />
    </ProfileWrapper>
  )
}

export default Profile

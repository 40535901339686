import { API } from 'configs/api'
import axios from 'configs/axios'

export const shopApi = {
  getLotteries: () => {
    return axios.get(API.getUserLotteries)
  },

  getInvoice: (body) => {
    return axios.get(`${API.getInvoice}/${body.id}?count=${body.count}`)
  },

  getLatestTickets: (count) => {
    return axios.get(`${API.getLatestLotteryTicket}?count=${count}`)
  },
}

import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from 'store/user/slice'
import { homeReducer } from 'store/home/slice'
import { farmReducer } from 'store/farm/slice'
import { cardReducer } from 'store/card/slice'
import { friendsReducer } from './friends/slice'
import { tasksReducer } from './tasks/slice'
import { gamesReducer } from './games/slice'
import { shopReducer } from './shop/slice'
import { profileReducer } from './profile/slice'

export const getStore = () => {
  const store = configureStore({
    reducer: {
      user: userReducer,
      friends: friendsReducer,
      home: homeReducer,
      farm: farmReducer,
      card: cardReducer,
      tasks: tasksReducer,
      games: gamesReducer,
      shop: shopReducer,
      profile: profileReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })

  return store
}

import { styled } from 'styled-components'
import { COLORS } from 'utils/constants'

export const ProfileFeedItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  background-color: ${COLORS.gray_3B};
  border-radius: 6px;
`

export const FeedItemImage = styled.img`
  background-color: ${COLORS.grey_d1};
  border-radius: 4px;
  width: 40px;
  aspect-ratio: 1/1;
`

export const FeedInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 4px;
`

export const FeedDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const FeedItemTitle = styled.p`
  color: ${COLORS.white};
  font-size: 14px;
  font-weight: 400;
`

export const FeedItemDescription = styled.p`
  color: ${COLORS.gray};
  font-size: 12px;
`

export const FeedItemStatisticIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const FeedItemDescriptionStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: ${COLORS.blue};
`

export const FeedItemValue = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const FeedItemValueText = styled.p`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${COLORS.white};
`

export const FeedItemValueIcon = styled.img`
  width: 16px;
  height: 16px;
`

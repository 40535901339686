import { USER_STATUSES } from 'utils/constants'
import * as S from './styled'

function StatusesContent() {
  return (
    <S.StatusesWrapper>
      {Object.entries(USER_STATUSES).map(([key, status]) => (
        <S.StatusItem key={key}>
          <S.StatusLeftSide>
            <S.StatusImage src={status.image} alt={status.title} />
            <S.StatusTitle>{status.title}</S.StatusTitle>
          </S.StatusLeftSide>
          <S.StatusCards>{status.cards} Cards</S.StatusCards>
        </S.StatusItem>
      ))}
    </S.StatusesWrapper>
  )
}

export default StatusesContent

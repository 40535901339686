import { Image } from 'assets/images'
import * as S from './styled'

function ProfileFeedSection({ section }) {
  return (
    <S.ProfileFeedItem onClick={section.onClick}>
      <S.FeedItemImage src={section.image} alt={section.image} />
      <S.FeedInfoWrapper>
        <S.FeedItemTitle>{section.title}</S.FeedItemTitle>
        <S.FeedDescriptionWrapper>
          {section.description && (
            <S.FeedItemDescription>{section.description}</S.FeedItemDescription>
          )}
          <S.FeedItemDescriptionStatistic>
            {section.statisticIcon && (
              <S.FeedItemStatisticIcon
                src={section.statisticIcon}
                alt={section.statisticIcon}
              />
            )}
            {section.statistic}
          </S.FeedItemDescriptionStatistic>
        </S.FeedDescriptionWrapper>
      </S.FeedInfoWrapper>
      {section.value && (
        <S.FeedItemValue>
          <S.FeedItemValueText>{section.value}</S.FeedItemValueText>
          <S.FeedItemValueIcon src={Image.coin} />
        </S.FeedItemValue>
      )}
    </S.ProfileFeedItem>
  )
}

export default ProfileFeedSection

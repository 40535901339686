import { GIF } from 'assets/gifs'
import { TICKET_IMAGES } from 'assets/images'
import Modal from 'common/Modal'
import * as S from './styled'

export function LotteryCongratulationsModal({ onClose, isOpen, lotteries }) {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <S.CongratulationsWrapper>
        <S.CongratulationsTop>
          <S.CongratulationImage
            src={GIF.chickenGlasses}
            alt='congratulations'
          />
          <S.CongratulationsTitle>Congratulations!</S.CongratulationsTitle>
        </S.CongratulationsTop>
        <S.TotalCoinInfo>
          {lotteries?.map((lottery, i) => (
            <S.TicketWrapper key={`lottery-${i}`}>
              {lottery?.map((ticketNumber, j) => (
                <S.TicketItem key={`lottery-item-${j}`}>
                  <S.TicketImage
                    src={TICKET_IMAGES[ticketNumber]}
                    alt='lottery'
                  />
                </S.TicketItem>
              ))}
            </S.TicketWrapper>
          ))}
        </S.TotalCoinInfo>
      </S.CongratulationsWrapper>
    </Modal>
  )
}

import CongratulationsBg from 'assets/images/congratulations.png'
import CoinImage from 'common/CoinImage'
import Modal from 'common/Modal'
import { getCardLabel } from 'utils/card'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

function CongratulationsModal({ onClose, isOpen, cardsCount, reward }) {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <S.CongratulationsWrapper>
        <S.CongratulationsTop>
          <S.CongratulationsImage
            width={142}
            height={94}
            src={CongratulationsBg}
            alt='Congratulations'
          />
          <S.CongratulationsTitle>Congratulations!</S.CongratulationsTitle>
        </S.CongratulationsTop>
        <S.TotalCoinInfo>
          <S.ReceivedCoin>You have received:</S.ReceivedCoin>
          <S.CoinValue>
            <S.CoinCardLabel>
              {cardsCount} {getCardLabel(cardsCount)} and
            </S.CoinCardLabel>
            <S.CoinLabel>
              <CoinImage size='16' /> {formatCoin(reward)}
            </S.CoinLabel>
          </S.CoinValue>
        </S.TotalCoinInfo>
      </S.CongratulationsWrapper>
    </Modal>
  )
}

export default CongratulationsModal

import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const User = styled.div`
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 16px;
  background-color: ${COLORS.black_28};
  border-radius: 6px;
  border: 1px solid ${COLORS.gray_3B};
`

export const UserInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: fit-content;
`

export const UserInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`

export const UserName = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
`

export const UserPoints = styled.div`
  display: flex;
  align-items: center;
`

export const UserPointsIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const UserPointsText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
  margin-right: 8px;
`

export const UserRank = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.blue};
  height: fit-content;
`

export const RankImage = styled.img`
  width: 24px;
  height: 22px;
`

import {
  openLink,
  openTelegramLink,
  shareStory,
  shareURL,
} from '@telegram-apps/sdk-react'
import Button from 'common/Button'
import { Dialog } from 'common/Dialog'
import SingleText from 'common/Input'
import { CheckTask } from 'components/DialogsContent/CheckTask'
import { InviteFriendDialogContent } from 'components/DialogsContent/InviteFrientDialogContent'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { incrementAvailableCards } from 'store/card/slice'
import { tasksSelector } from 'store/tasks/selectors'
import { tasksActions } from 'store/tasks/slice'
import { userSelector } from 'store/user/selectors'
import { userActions } from 'store/user/slice'
import {
  COLORS,
  CONFIRMATION_TASKS,
  DAILY_TASK_TYPES,
  LOADING_STATUSES,
  TASK_BUTTONS_STATES,
  TASK_PRIORITIES,
  TASK_SOURCES,
  TASK_SPONSORS,
  TASK_TYPES,
} from 'utils/constants'
import { isTaskRelated } from 'utils/taskList'
import { getOneTimeTaskText, getTaskStatus } from 'utils/taskText'
import CongratulationsModal from './CongratulationsModal'
import Task from './Task'
import * as S from './styled'

const tabs = [
  {
    value: TASK_TYPES.all,
    label: 'All',
  },
  {
    value: TASK_TYPES.once,
    label: 'One time tasks',
  },
  {
    value: TASK_TYPES.daily,
    label: 'Daily tasks',
  },
]

function TasksList({ tasks, dailyTasks, socialTasks }) {
  const dispatch = useDispatch()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { user } = useSelector(userSelector)
  const {
    storyImgUrl,
    tasksReward,
    tasksCardsCount,
    completeTasksListStatus,
    completeDetectableTaskStatus,
    completeTaskListStatus,
  } = useSelector(tasksSelector)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false)
  const [selectedModalTask, setSelectedModalTask] = useState(null)
  const [selectedTaskId, setSelectedTaskId] = useState(null)
  const [selectedTab, setSelectedTab] = useState(TASK_TYPES.all)
  const [validationText, setValidationText] = useState('')
  const [loading, setIsLoading] = useState(false)

  const highPriorityTasks = useMemo(
    () =>
      socialTasks
        .filter((task) => task.priority === TASK_PRIORITIES.high)
        .sort(
          (a, b) =>
            (a.sponsor === TASK_SPONSORS.okx ? -1 : 1) -
            (b.sponsor === TASK_SPONSORS.okx ? -1 : 1)
        ),
    [socialTasks]
  )

  const mediumPriorityTasks = useMemo(
    () =>
      socialTasks.filter((task) => task.priority === TASK_PRIORITIES.medium),
    [socialTasks]
  )

  const closeDialog = () => {
    setSelectedTaskId(null)
    setIsDialogOpen(false)
  }

  const closeCheckModal = () => {
    setIsCheckModalOpen(false)
    setValidationText('')
    setSelectedModalTask(null)
  }

  const handleSendInTelegram = () => {
    shareURL(
      `https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}/?start=${user?.telegramId}`,
      'Join me on Voyager and start collecting crypto rewards! 🪙💰 Track your earnings, get bonus coins, and level up your crypto game. Let’s grow our coin collection together!🚀✨'
    )
  }

  const onCopyLinkClick = () => {
    setIsLoading(true)
    const link = `https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}/?start=${user?.telegramId}`
    const textToCopy = `${link}\n\nJoin me on Voyager and start collecting crypto rewards! 🪙💰 Track your earnings, get bonus coins, and level up your crypto game. Let’s grow our coin collection together!🚀✨`

    navigator.clipboard.writeText(textToCopy)
    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }

  const onTaskClick = (task) => {
    if (task.completed) {
      setSelectedTaskId(task.task._id)
      dispatch(tasksActions.completeTask({ taskId: task._id }))
      dispatch(userActions.addTotalCoins(task.task.reward))
      dispatch(incrementAvailableCards(task.task.cardsCount))
    } else {
      setIsDialogOpen(true)
    }
  }

  useEffect(() => {
    if (completeTaskListStatus === LOADING_STATUSES.succeeded) {
      setIsCheckModalOpen(false)
      setValidationText('')
      setIsModalOpen(true)
    }
  }, [completeTaskListStatus])

  useEffect(() => {
    if (completeDetectableTaskStatus === LOADING_STATUSES.succeeded) {
      const completedTask = { ...selectedModalTask, completed: true }
      setSelectedModalTask(completedTask)
    }
  }, [completeDetectableTaskStatus])

  const openCheckModal = (task) => {
    setSelectedModalTask(task)
    setIsCheckModalOpen(true)
  }
  const onSubscribe = (task) => {
    const getTelegramLink = (chatId) => {
      const botLinkApp = `tg://resolve?domain=${chatId.replace('@', '')}`
      const botLinkWeb = `https://t.me/${chatId.replace('@', '')}`
      return { botLinkApp, botLinkWeb }
    }

    if (task.source === TASK_SOURCES.telegram) {
      dispatch(tasksActions.completeSocialTask({ taskId: task._id }))
      const { botLinkWeb } = getTelegramLink(task.chatId)
      openTelegramLink(botLinkWeb)
    } else if (task.type === 'subscribe') {
      dispatch(tasksActions.completeDailyTask({ taskId: task._id }))
      const { botLinkWeb } = getTelegramLink(task.chatId)
      openTelegramLink(botLinkWeb)
    } else if (task.type === DAILY_TASK_TYPES.story) {
      dispatch(tasksActions.completeDailyTask({ taskId: task._id }))
      shareStory(storyImgUrl, {
        text: 'Just posted a story from @VoyagerCryptoBot and earned myself 2500 ERV coins! 🤑✨ #VoyagerVibes #CoinBoost',
      })
    } else if (task.source === TASK_SOURCES.game) {
      dispatch(tasksActions.completeSocialTask({ taskId: task._id }))
      openTelegramLink(task.link)
    } else {
      dispatch(tasksActions.completeSocialTask({ taskId: task._id }))
      openLink(task.link)
    }
  }

  const onCheck = (task) => {
    if (task.completed) {
      if (task.source === TASK_SOURCES.telegram) {
        dispatch(tasksActions.gainSocialTask({ taskId: task._id }))
        setSelectedTaskId(task._id)
      } else if (task.type === DAILY_TASK_TYPES.subscribe) {
        dispatch(tasksActions.gainDailyTask({ taskId: task._id }))
        setSelectedTaskId(task._id)
      } else if (task.type === DAILY_TASK_TYPES.story) {
        dispatch(tasksActions.gainDailyTask({ taskId: task._id }))
        setSelectedTaskId(task._id)
      } else {
        dispatch(tasksActions.gainSocialTask({ taskId: task._id }))
        setSelectedTaskId(task._id)
      }
    }
  }

  const isCompletedUngainedTask = useMemo(
    () => (task) =>
      task.completed &&
      !task.gained &&
      task.type !== DAILY_TASK_TYPES.subscribe,
    []
  )

  const isAnyTaskDone = useMemo(
    () => tasks.some(isCompletedUngainedTask),
    [tasks, isCompletedUngainedTask]
  )

  const onClaimAllClick = () => {
    dispatch(tasksActions.completeTasks())
  }

  const filterSocialTasks = useMemo(
    () => (task) => task.priority === TASK_PRIORITIES.low,
    []
  )

  const filteredSocialTasks = useMemo(
    () => socialTasks.filter(filterSocialTasks),
    [socialTasks, filterSocialTasks]
  )

  useEffect(() => {
    if (completeTasksListStatus === LOADING_STATUSES.succeeded) {
      dispatch(userActions.addTotalCoins(tasksReward))
      setSelectedTaskId(TASK_TYPES.all)
      setIsModalOpen(true)

      dispatch(tasksActions.resetCompleteTasksStatus())
    }
  }, [completeTasksListStatus])

  return (
    <>
      <S.TasksView>
        <S.HeaderContainer>
          <S.HeaderTitleContainer>
            <S.Title>Available tasks</S.Title>
            <SingleText
              fontSize='14px'
              textColor={COLORS.blue}
              margin='0px 0 16px 0'
            >
              Complete any task and receive an instant reward!
            </SingleText>
          </S.HeaderTitleContainer>
          <Button
            maxWidth='100px'
            padding='10px 14px'
            onClick={onClaimAllClick}
            isLoading={completeTasksListStatus === LOADING_STATUSES.pending}
            isDisabled={!isAnyTaskDone}
            text='Claim All'
          />
        </S.HeaderContainer>
        <S.TabsWrapper>
          {tabs.map((tab) => (
            <S.Tab key={tab.value} onClick={() => setSelectedTab(tab.value)}>
              <SingleText
                fontSize='14px'
                textColor={
                  selectedTab === tab.value ? COLORS.blue : COLORS.white_f8
                }
                fontWeight='600'
              >
                {tab.label}
              </SingleText>
            </S.Tab>
          ))}
        </S.TabsWrapper>
        <S.TasksList>
          {isTaskRelated(selectedTab, TASK_TYPES.once) &&
            tasks.map((el) => (
              <Task
                key={el.task._id}
                taskType={TASK_TYPES.once}
                reward={el.task.reward}
                onClick={() => onTaskClick(el)}
                cardsCount={el.task.cardsCount}
                friendsCount={el.task.friendsCount}
                onClose={() => setIsModalOpen(false)}
                text={getTaskStatus(el)}
                disabled={el.gained}
                isOpen={selectedTaskId === el.task._id && isModalOpen}
                special
              />
            ))}

          {isTaskRelated(selectedTab, TASK_TYPES.once) &&
            mediumPriorityTasks.map((el) => (
              <Task
                key={el._id}
                taskType={TASK_TYPES.daily}
                reward={el.reward}
                cardsCount={el.cardsCount}
                description={el.description}
                icon={el.source}
                onClick={() => openCheckModal(el)}
                onClose={() => setIsModalOpen(false)}
                text={getOneTimeTaskText(el.gained, el.source)}
                disabled={el.gained}
                isOpen={selectedTaskId === el._id && isModalOpen}
              />
            ))}
          {isTaskRelated(selectedTab, TASK_TYPES.once) &&
            filteredSocialTasks.map((el) => (
              <Task
                key={el._id}
                taskType={TASK_TYPES.daily}
                reward={el.reward}
                cardsCount={el.cardsCount}
                description={el.description}
                icon={el.source}
                onClick={() => openCheckModal(el)}
                onClose={() => setIsModalOpen(false)}
                text={getOneTimeTaskText(el.gained, el.source)}
                disabled={el.gained}
                isOpen={selectedTaskId === el._id && isModalOpen}
              />
            ))}
          {isTaskRelated(selectedTab, TASK_TYPES.daily) &&
            dailyTasks?.map((el) => (
              <Task
                key={el._id}
                taskType={TASK_TYPES.daily}
                reward={el.reward}
                cardsCount={el.cardsCount}
                description={el.description}
                icon={TASK_SOURCES.telegram}
                onClick={() => openCheckModal(el)}
                onClose={() => setIsModalOpen(false)}
                text={
                  el.gained
                    ? TASK_BUTTONS_STATES.done
                    : TASK_BUTTONS_STATES.start
                }
                disabled={el.gained}
                isOpen={selectedTaskId === el._id && isModalOpen}
              />
            ))}
          {isTaskRelated(selectedTab, TASK_TYPES.once) &&
            highPriorityTasks?.map((el) => (
              <Task
                key={el._id}
                taskType={TASK_TYPES.daily}
                reward={el.reward}
                cardsCount={el.cardsCount}
                description={el.description}
                icon={el.sponsor}
                onClick={() => openCheckModal(el)}
                onClose={() => setIsModalOpen(false)}
                text={
                  el.gained
                    ? TASK_BUTTONS_STATES.done
                    : TASK_BUTTONS_STATES.start
                }
                disabled={el.gained}
                isOpen={selectedTaskId === el._id && isModalOpen}
              />
            ))}
        </S.TasksList>
      </S.TasksView>
      <Dialog
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        title='Invite a friend'
      >
        <InviteFriendDialogContent
          user={user}
          isLoading={loading}
          handleSendInTelegram={handleSendInTelegram}
          onCopyLinkClick={onCopyLinkClick}
        />
      </Dialog>
      <Dialog
        isOpen={isCheckModalOpen}
        closeDialog={closeCheckModal}
        top={CONFIRMATION_TASKS.includes(selectedModalTask?.sponsor)}
      >
        <CheckTask
          task={selectedModalTask}
          onSubscribe={onSubscribe}
          onCheck={onCheck}
          value={validationText}
          setValue={setValidationText}
        />
      </Dialog>
      <CongratulationsModal
        reward={tasksReward}
        cardsCount={tasksCardsCount}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen && selectedTaskId === TASK_TYPES.all}
      />
    </>
  )
}

export default TasksList

import CardsIcon from 'assets/icons/CardsIcon'
import GameIcon from 'assets/icons/GameIcon'
import HomeIcon from 'assets/icons/Home'
import ProfileIcon from 'assets/icons/ProfileIcon'
import TasksIcon from 'assets/icons/Tasks'
import * as S from 'components/BottomMenu/styled'
import { useLocation } from 'react-router-dom'
import { COLORS, ROUTES } from 'utils/constants'

const menuItems = [
  { route: ROUTES.home, label: 'Home', Icon: HomeIcon },
  { route: ROUTES.games, label: 'Games', Icon: GameIcon },
  { route: ROUTES.tasks, label: 'Tasks', Icon: TasksIcon },
  { route: ROUTES.cards, label: 'Cards', Icon: CardsIcon },
  { route: ROUTES.profile, label: 'Profile', Icon: ProfileIcon },
]

function BottomMenu() {
  const location = useLocation()

  return (
    <S.Menu>
      {menuItems.map(({ route, label, Icon }) => (
        <S.MenuItem
          key={route}
          to={route}
          color={
            route === ROUTES.home
              ? location.pathname === route
                ? COLORS.blue
                : COLORS.white
              : location.pathname.startsWith(route)
              ? COLORS.blue
              : COLORS.white
          }
        >
          <Icon
            color={
              route === ROUTES.home
                ? location.pathname === route
                  ? COLORS.blue
                  : COLORS.white
                : location.pathname.startsWith(route)
                ? COLORS.blue
                : COLORS.white
            }
          />
          <span>{label}</span>
        </S.MenuItem>
      ))}
    </S.Menu>
  )
}

export default BottomMenu

import am from './am.png'
import au from './au.png'
import avatar from './avatar.png'
import background from './Background.jpeg'
import bottleCapOrange from './bottle-cap-orange.png'
import bottleCapGreen from './bottle-cap-green.png'
import card from './card.png'
import ch from './ch.png'
import coin from './coin.png'
import de from './de.png'
import dogyCoin from './dogy-coin.png'
import eg from './eg.png'
import farmBig from './farm-big-size.png'
import farmSmall from './farm-small.png'
import flag from './flag.png'
import flyingMoney from './flying-money.png'
import fr from './fr.png'
import gamepad from './gamepad.png'
import gr from './gr.png'
import ind from './in.png'
import it from './it.png'
import jp from './jp.png'
import ksa from './ksa.png'
import millionaireCircle from './millionaireCircle.png'
import moneyBag from './money-bag.png'
import moneyMouth from './money-mouth.png'
import okx from './onboarding-okx.png'
import onboardingScreenOverlay from './onboarding-screen.png'
import voyager from './onboarding-voyager.png'
import x from './onboarding-x.png'
import personEmoji from './person-emoji.png'
import planeTicket from './plane-ticket.png'
import plane from './plane.png'
import puzzleGame from './puzzleGame.png'
import QR from './qr.png'
import quizGame from './quizGame.png'
import rok from './rok.png'
import roulette from './roulette.png'
import rouletteGame from './rouletteGame.png'
import ru from './ru.png'
import testCard from './test-card.jpeg'
import be from './be.png'
import uk from './uk.png'
import usa from './usa.png'
import telegramStar from './telegram_star.png'
import isolation from './isolation.png'
import ticket from './ticket.png'
import sad from './sad.png'
import ranksCup from './ranks-cup.png'
import friends from './friends.png'
import medal from './medal.png'
import cards from './cards.png'
import newCommerce from './new-commerce.png'
import tourist from './tourist.png'
import steward from './steward.png'
import pilot from './pilot.png'
import profileTicket from './profile-ticket.png'
import ranked1 from './ranked-1.png'
import ranked2 from './ranked-2.png'
import ranked3 from './ranked-3.png'

// TODO change constant to IMAGES
export const Image = {
  farmBig,
  farmSmall,
  onboardingScreenOverlay,
  personEmoji,
  card,
  background,
  testCard,
  QR,
  avatar,
  coin,
  okx,
  voyager,
  x,
  gamepad,
  quizGame,
  millionaireCircle,
  puzzleGame,
  flag,
  rouletteGame,
  roulette,
  telegramStar,
  ticket,
  sad,
  ranksCup,
  friends,
  medal,
  cards,
  newCommerce,
  tourist,
  steward,
  pilot,
  profileTicket,
}

export const ROULETTE_IMAGES = {
  1: bottleCapOrange,
  2: dogyCoin,
  3: flyingMoney,
  4: moneyMouth,
  5: plane,
  6: moneyBag,
  7: planeTicket,
  8: coin,
}

export const TICKET_IMAGES = {
  1: bottleCapOrange,
  2: dogyCoin,
  3: flyingMoney,
  4: moneyMouth,
  5: plane,
  6: moneyBag,
  7: planeTicket,
  8: coin,
  9: isolation,
  10: bottleCapGreen,
}

export const COUNTRY_IMAGES = {
  1: de,
  2: usa,
  3: uk,
  4: jp,
  5: it,
  6: eg,
  7: fr,
  8: au,
  9: am,
  10: ch,
  11: be,
  12: rok,
  13: ksa,
  14: ind,
  15: ru,
  16: gr,
}

export const TOP_RANKS = {
  1: ranked1,
  2: ranked2,
  3: ranked3,
}

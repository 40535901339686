import axios from 'configs/axios'
import { API } from 'configs/api'

export const profileApi = {
  getTopRankedUsers: () => {
    return axios.get(API.getTopRankedUsers)
  },
  getUserRank: () => {
    return axios.get(API.getUserRank)
  },
}

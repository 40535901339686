import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes } from './router'

export function Router() {
  return (
    <Routes>
      {routes.map(({ path, component: Component, children }) => (
        <Route key={path} path={path} element={<Component />}>
          {children &&
            children.map(
              ({ path: childPath, component: ChildComponent, index }) => (
                <Route
                  index={index}
                  key={childPath}
                  path={childPath}
                  element={<ChildComponent />}
                />
              )
            )}
        </Route>
      ))}
    </Routes>
  )
}

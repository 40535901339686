import React from 'react'

function TicketIcon() {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='512.000000pt'
      height='512.000000pt'
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill='#F8F8F8'
        stroke='none'
      >
        <path
          d='M1874 3952 c-704 -499 -1297 -920 -1317 -935 -20 -15 -37 -36 -37
   -46 0 -9 14 -36 31 -59 33 -45 59 -117 59 -162 0 -89 -69 -199 -152 -241 -29
   -15 -38 -25 -38 -45 0 -17 45 -89 135 -213 l135 -188 0 -704 c0 -426 4 -710
   10 -719 7 -11 30 -15 87 -16 174 -2 283 -106 287 -273 1 -36 5 -72 9 -78 7
   -10 345 -13 1637 -13 1825 0 1665 -7 1654 74 -8 57 17 141 56 189 45 53 107
   86 192 100 l73 13 3 818 c1 597 -1 822 -9 832 -6 8 -26 14 -43 14 -59 0 -132
   29 -179 70 -61 54 -88 113 -92 201 -2 39 -7 74 -10 78 -4 3 -34 8 -68 11 l-62
   5 173 123 c188 134 192 140 145 196 -94 111 -84 265 23 370 30 29 54 60 54 70
   0 22 -935 1318 -959 1329 -13 6 -35 -1 -77 -25 -52 -29 -67 -33 -134 -33 -65
   0 -83 4 -129 30 -34 19 -68 49 -93 82 -27 37 -45 53 -61 53 -13 -1 -559 -381
   -1303 -908z m1333 747 c101 -93 251 -124 375 -79 35 13 64 18 69 13 5 -5 202
   -276 438 -603 l429 -594 -39 -44 c-94 -107 -112 -276 -43 -401 20 -36 22 -47
   12 -57 -7 -7 -96 -71 -198 -143 l-185 -131 -387 0 c-214 0 -388 2 -388 4 0 3
   143 106 319 231 214 151 321 233 325 248 3 12 6 44 6 70 0 26 7 81 15 123 9
   41 12 80 8 87 -4 8 -122 172 -261 364 -164 227 -261 353 -275 357 -12 3 -35 6
   -50 6 -16 0 -70 10 -121 21 l-92 22 -980 -693 c-538 -381 -989 -702 -1001
   -713 -16 -14 -23 -33 -25 -71 -3 -47 -5 -51 -30 -54 -39 -5 -51 -26 -53 -97
   -3 -81 -32 -142 -92 -195 -57 -50 -135 -75 -207 -66 -58 6 -86 -11 -86 -53 0
   -21 -12 -10 -66 64 -95 131 -93 118 -34 175 29 28 63 75 78 107 23 50 27 71
   27 153 0 84 -3 102 -29 153 -15 32 -26 60 -25 61 2 2 522 369 1154 816 633
   448 1195 846 1250 886 55 40 104 73 109 73 5 1 29 -17 53 -40z m102 -634 c47
   -8 86 -17 89 -19 2 -3 109 -150 238 -328 223 -307 234 -325 228 -358 -3 -19
   -11 -70 -18 -113 l-11 -77 -360 -255 -360 -255 -258 0 -257 0 25 50 c26 50 28
   52 107 65 54 8 82 35 82 79 0 29 -8 45 -43 80 l-42 43 6 70 c7 64 5 73 -14 92
   -28 28 -78 27 -131 -3 l-42 -24 -64 30 c-58 27 -68 29 -95 18 -43 -18 -52 -42
   -44 -117 l7 -64 -46 -49 c-53 -56 -58 -88 -22 -125 16 -16 40 -25 80 -30 56
   -7 57 -8 81 -56 14 -27 25 -51 25 -54 0 -3 -274 -5 -610 -5 l-610 0 0 33 c1
   31 26 50 967 715 736 520 972 682 988 678 11 -4 58 -13 104 -21z m971 -1523
   c0 -134 134 -291 275 -323 l55 -12 0 -743 c0 -800 3 -754 -48 -754 -34 0 -123
   -50 -172 -96 -59 -55 -99 -135 -107 -210 l-6 -54 -1552 0 -1552 0 -6 54 c-16
   156 -164 299 -328 313 l-59 6 2 741 3 741 64 7 c160 18 296 149 317 306 l7 52
   1553 0 1554 0 0 -28z'
        />
        <path
          d='M3013 3605 c-28 -20 -35 -53 -22 -116 l10 -54 -40 -35 c-46 -41 -64
   -80 -52 -113 14 -35 34 -47 97 -57 33 -5 60 -13 62 -17 1 -5 16 -32 32 -60 51
   -89 115 -86 165 9 l29 57 60 6 c115 14 139 80 60 165 l-45 48 9 53 c11 60 3
   90 -30 113 -31 22 -49 20 -111 -10 l-53 -26 -52 26 c-58 30 -88 32 -119 11z'
        />
        <path
          d='M1499 2112 c-9 -4 -39 -35 -67 -69 -28 -34 -80 -83 -114 -109 -35
   -27 -66 -53 -70 -59 -4 -5 -8 -190 -8 -411 0 -306 3 -404 13 -415 6 -8 45 -41
   85 -73 39 -32 72 -61 72 -65 0 -3 22 -30 49 -59 l48 -52 1218 0 1218 0 41 48
   c22 26 69 74 103 107 l63 60 0 447 0 447 -105 106 -105 105 -1212 -1 c-667 0
   -1220 -3 -1229 -7z m2486 -169 l74 -78 1 -400 0 -400 -79 -82 -79 -83 -1180 0
   -1179 0 -51 58 c-28 32 -73 76 -101 97 l-51 39 0 371 0 370 40 28 c21 15 65
   57 97 92 l58 64 1187 1 1188 0 75 -77z'
        />
        <path
          d='M1875 1716 c-21 -9 -38 -29 -54 -64 -26 -55 -29 -57 -103 -67 -34 -4
   -53 -13 -68 -32 -32 -41 -26 -73 26 -123 45 -45 45 -45 38 -95 -13 -92 -5
   -116 46 -133 23 -9 39 -6 87 17 l59 28 48 -24 c61 -31 111 -28 139 8 17 24 18
   33 9 85 l-10 59 44 47 c74 80 56 150 -43 163 -70 9 -70 10 -97 63 -37 73 -69
   91 -121 68z'
        />
        <path
          d='M2665 1716 c-21 -9 -38 -29 -54 -64 -26 -55 -29 -57 -103 -67 -34 -4
   -53 -13 -68 -32 -31 -40 -25 -68 25 -122 l44 -47 -6 -71 c-5 -63 -3 -73 16
   -92 28 -28 74 -27 130 3 l45 23 61 -29 c70 -32 101 -29 131 17 13 21 15 35 7
   82 l-9 58 43 47 c73 80 54 150 -44 163 -70 9 -70 10 -97 63 -37 73 -69 91
   -121 68z'
        />
        <path
          d='M3459 1719 c-18 -7 -37 -30 -58 -68 l-31 -58 -60 -7 c-47 -6 -63 -12
   -80 -33 -31 -40 -25 -68 26 -123 l46 -49 -7 -55 c-9 -69 -1 -102 27 -117 33
   -18 68 -13 117 14 l43 25 63 -29 c70 -33 101 -30 131 16 14 21 15 35 7 85 l-9
   59 39 38 c51 50 61 75 43 118 -15 35 -45 49 -121 57 -24 2 -35 12 -56 51 -41
   76 -70 94 -120 76z'
        />
      </g>
    </svg>
  )
}

export default TicketIcon

import { Image } from 'assets/images'
import Button from 'common/Button'
import UserListItem from 'components/UserListItem'
import { useMemo } from 'react'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function FriendsList({
  friends,
  friendsCount,
  onClaimClick,
  farmedCoins,
}) {
  const isClaimButtonDisable = useMemo(() => {
    return friends.reduce((elem, acc) => {
      return elem + acc.coins
    }, 0)
  }, [friends])

  return (
    <S.FriendsListWrapper>
      <S.FriendsListHeader>
        <S.FriendsListHeaderLeftPart>
          <S.HeaderTitle>
            Get <span>10%</span> from your friends
          </S.HeaderTitle>
          <S.HeaderSubtitle>
            Totally claimed: &nbsp;
            <S.FarmIcon src={Image.coin} />
            &nbsp; {formatCoin(farmedCoins)}
          </S.HeaderSubtitle>
        </S.FriendsListHeaderLeftPart>
        <Button
          text='Claim'
          onClick={onClaimClick}
          isDisabled={!isClaimButtonDisable}
        />
      </S.FriendsListHeader>
      <S.FriendsCount>
        Friends <span>({friendsCount})</span>
      </S.FriendsCount>
      <S.FriendsList>
        {friends?.map((friend) => (
          <UserListItem
            key={friend.referral._id}
            username={friend.referral.username}
            coins={friend.referral.coins}
            showCoins={friend.profitable}
          />
        ))}
      </S.FriendsList>
    </S.FriendsListWrapper>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import {
  getLotteries,
  getInvoiceLink,
  getLatestLotteryTickets,
} from 'store/shop/actions'
import { LOADING_STATUSES } from 'utils/constants'

const initialState = {
  lotteries: [],
  boughtLotteries: null,
  latestInvoiceLink: null,
  getLotteriesStatus: LOADING_STATUSES.idle,
  getInvoiceLinkStatus: LOADING_STATUSES.idle,
  getLatestLotteryTicketsStatus: LOADING_STATUSES.idle,
}

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    resetBoughtLotteries: (state) => {
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.idle
      state.getInvoiceLinkStatus = LOADING_STATUSES.idle
      state.boughtLotteries = null
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getLotteries.pending, (state) => {
      state.getLotteriesStatus = LOADING_STATUSES.pending
    })
    addCase(getLotteries.fulfilled, (state, { payload }) => {
      state.lotteries = payload
      state.getLotteriesStatus = LOADING_STATUSES.succeeded
    })
    addCase(getLotteries.rejected, (state) => {
      state.getLotteriesStatus = LOADING_STATUSES.failed
    })

    addCase(getInvoiceLink.pending, (state) => {
      state.latestInvoiceLink = null
      state.getInvoiceLinkStatus = LOADING_STATUSES.pending
    })
    addCase(getInvoiceLink.fulfilled, (state, { payload }) => {
      state.latestInvoiceLink = payload
      state.getInvoiceLinkStatus = LOADING_STATUSES.succeeded
    })
    addCase(getInvoiceLink.rejected, (state) => {
      state.getInvoiceLinkStatus = LOADING_STATUSES.failed
    })

    addCase(getLatestLotteryTickets.pending, (state) => {
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.pending
    })
    addCase(getLatestLotteryTickets.fulfilled, (state, { payload }) => {
      state.boughtLotteries = payload
      state.lotteries = [...state.lotteries, ...payload]
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.succeeded
    })
    addCase(getLatestLotteryTickets.rejected, (state) => {
      state.getLatestLotteryTicketsStatus = LOADING_STATUSES.failed
    })
  },
})

export const shopReducer = shopSlice.reducer

export const shopActions = {
  getLotteries,
  getInvoiceLink,
  getLatestLotteryTickets,
  ...shopSlice.actions,
}

import { TICKET_IMAGES } from 'assets/images'
import * as S from './styled'

function LotteryTicket({ ticketNumbers }) {
  return (
    <S.TicketWrapper>
      {ticketNumbers.map((ticketNumber, i) => (
        <S.TicketItem key={`ticket-item-${i}`}>
          <S.TicketImage src={TICKET_IMAGES[ticketNumber]} alt='ticket' />
        </S.TicketItem>
      ))}
    </S.TicketWrapper>
  )
}

export default LotteryTicket

import { overflowScroll } from 'common/SharedStyles/styled'
import { styled } from 'styled-components'
import { COLORS } from 'utils/constants'

export const ProfileRanksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 32px;
  padding: 16px;
  ${overflowScroll}
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1;
`

export const UserImage = styled.img`
  max-width: 56px;
  max-height: 56px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PageTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.white};
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${COLORS.white};
  font-size: 14px;
`
export const UserStatus = styled.p`
  color: ${COLORS.blue};
  font-size: 12px;
`

export const UserBalance = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.white};
  font-size: 12px;
  gap: 4px;
`

export const StyledImage = styled.img`
  width: 16px;
  height: 16px;
`

export const RanksFeed = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const formatNumberSuffix = (number) => {
  if (typeof number !== 'number' || Number.isNaN(number)) {
    return ''
  }

  const lastTwoDigits = number % 100

  let suffix = 'th'
  if (lastTwoDigits < 11 || lastTwoDigits > 13) {
    const lastDigit = number % 10
    if (lastDigit === 1) suffix = 'st'
    else if (lastDigit === 2) suffix = 'nd'
    else if (lastDigit === 3) suffix = 'rd'
  }

  return number + suffix
}

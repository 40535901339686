import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const InviteFriendDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-block: 36px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const QrCode = styled.div`
  width: 272px;
  height: 272px;
  min-width: 272px;
  min-height: 272px;
  border-radius: 10px;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PersonalDetailsDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid res;
  padding-block: 16px;
`

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

export const PersonalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`

export const AnalyticsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 6px;
  border: 1px solid ${COLORS.gray_3B};
  border-radius: 6px;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`

export const Title = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.white_f8};
`

export const Value = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.white_f8};
  font-weight: 400;
`

export const CoinIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const RightSpace = styled.div``

export const AboutUsContainer = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  padding-top: 32px;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  ${overflowScroll}
`

export const LotteriesContainer = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  padding-top: 32px;
  align-items: center;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  overflow-x: hidden;
  ${overflowScroll}
`

export const PartContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LinksPartContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PartTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${COLORS.white_f8};
`

export const StepsContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const Step = styled.li`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
  list-style-position: inside;
`

export const StepTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.blue};
`

export const AboutText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
`

export const CheckTaskContent = styled.div`
  width: 100%;
  display: flex;
  max-height: calc(100dvh - 200px);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  ${overflowScroll}
`

export const TaskPoints = styled.div`
  display: flex;
  gap: 4px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const OrDivider = styled.p`
  color: ${COLORS.white};
  text-align: center;
`

export const TaskCardInvitationRewardCard = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  color: ${COLORS.blue};
`

export const StatusesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0 12px;
`

export const StatusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${COLORS.gray_3B};
  border-radius: 8px;
`

export const StatusLeftSide = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

export const StatusImage = styled.img`
  width: 32px;
  height: 32px;
`

export const StatusTitle = styled.p`
  color: ${COLORS.white};
  font-size: 12px;
`

export const StatusCards = styled.p`
  color: ${COLORS.blue};
  font-size: 12px;
`

import { createAsyncThunk } from '@reduxjs/toolkit'
import { shopApi } from './services'

export const getLotteries = createAsyncThunk(
  'shop/getLotteries',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await shopApi.getLotteries()

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getInvoiceLink = createAsyncThunk(
  'shop/getInvoice',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await shopApi.getInvoice(body)

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getLatestLotteryTickets = createAsyncThunk(
  'shop/getLatestLotteryTickets',
  async (count, { rejectWithValue }) => {
    try {
      const { data } = await shopApi.getLatestTickets(count)

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

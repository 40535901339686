import { createAsyncThunk } from '@reduxjs/toolkit'
import { profileApi } from './services'

export const getTopRankedUsers = createAsyncThunk(
  'profile/top-ranked',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await profileApi.getTopRankedUsers()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getUserRank = createAsyncThunk(
  'profile/rank',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await profileApi.getUserRank()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const CongratulationsWrapper = styled.div`
  padding-block: 28px;
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CongratulationsTop = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CongratulationImage = styled.img`
  width: 120px;

  @media (max-width: 480px) {
    width: 100px;
  }

  @media (max-width: 350px) {
    width: 80px;
  }
`

export const CongratulationsTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.blue};
`

export const TotalCoinInfo = styled.div`
  width: 100%;
  gap: 12px;
  max-height: 220px;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${overflowScroll}
`

export const ReceivedCoin = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.gray};
`

export const TicketWrapper = styled.div`
  display: grid;
  background-color: ${COLORS.blue_004};
  padding: 12px 4px;
  border-radius: 8px;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
`

export const TicketItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const TicketImage = styled.img`
  width: 48px;
  background-color: ${COLORS.blue_01};
  padding: 4px;
  border-radius: 2px;

  @media (max-width: 480px) {
    width: 33px;
  }

  @media (max-width: 350px) {
    width: 28px;
  }
`

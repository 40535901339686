import LotteryTicket from 'components/LotteryTicket'
import * as S from './styled'

export function LotteriesContent({ lotteries }) {
  return (
    <S.LotteriesContainer>
      {lotteries.map((lottery, i) => (
        <LotteryTicket key={`user-lottery-${i}`} ticketNumbers={lottery} />
      ))}
    </S.LotteriesContainer>
  )
}

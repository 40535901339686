import styled from 'styled-components'
import { COLORS } from 'utils/constants'
import { Image } from 'assets/images'

export const OnboardingContainer = styled.div`
  height: 100dvh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0;
`

export const OnboardingScreenOverlay = styled.img`
  width: 100%;
  margin-block: 56px 0;
`

export const OnboardingScreenOverlayBlock = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${Image.onboardingScreenOverlay}');
`

export const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  justify-content: center;
  place-items: center;
`

export const OnboardingImage = styled.img``

export const LoadingText = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white};
  margin-bottom: 48px;
  margin-top: 6px;
`

export const OnboardingTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: ${COLORS.white_f8};
  text-align: center;
`

export const OnboardingSubtitle = styled.p`
  padding: 24px 24px 48px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.white_f8};
  text-align: center;
  margin-top: 8px;
`

import { Image, TOP_RANKS } from 'assets/images'
import { ButtonText } from 'components/NavBar/styled'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

function UserListItem({ username, coins, showCoins, rank, showFullCoins }) {
  return (
    <S.User>
      <S.UserAvatar src={Image.avatar} alt={username} />
      <S.UserInfoWrapper>
        <S.UserInfo>
          <S.UserName>
            <ButtonText maxWidth='120'>{username}</ButtonText>
          </S.UserName>
          {showCoins || showFullCoins ? (
            <S.UserPoints>
              <S.UserPointsText>
                {formatCoin(coins, showFullCoins)}
              </S.UserPointsText>
              <S.UserPointsIcon src={Image.coin} />
            </S.UserPoints>
          ) : null}
        </S.UserInfo>
        {!!rank && (
          <S.UserRank>
            {rank > 3 ? (
              `#${rank}`
            ) : (
              <S.RankImage src={TOP_RANKS[rank]} alt={rank} />
            )}
          </S.UserRank>
        )}
      </S.UserInfoWrapper>
    </S.User>
  )
}

export default UserListItem

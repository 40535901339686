import { Image } from 'assets/images'
import { LotteriesContent } from 'components/DialogsContent/LotteriesContent'
import * as S from './styled'

function LotteriesDialogContent({ lotteries }) {
  return lotteries?.length ? (
    <LotteriesContent lotteries={lotteries} />
  ) : (
    <S.EmptyLotteriesWrapper>
      <S.EmptyLotteriesImage src={Image.sad} alt='No lotteries' />
      <S.EmptyLotteriesDescription>
        You don&apos;t have any lotteries
      </S.EmptyLotteriesDescription>
    </S.EmptyLotteriesWrapper>
  )
}

export default LotteriesDialogContent

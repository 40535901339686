import React, { useEffect, useState, memo, useRef } from 'react'
import { Card } from 'components/Card'
import { useDispatch, useSelector } from 'react-redux'
import { incrementAvailableCardsByOne } from 'store/card/actions'
import { CardPopup } from 'components/CardPopup'
import { cardSelector } from 'store/card/selectors'
import { CardSkeleton } from 'components/Skeletons/CardSkeleton'
import { userActions } from 'store/user/slice'
import { cardActions } from 'store/card/slice'
import { LOADING_STATUSES } from 'utils/constants'
import * as S from './styled'

function CardGrid() {
  const {
    cardsData: cards,
    availableCards,
    cardsTimeLeft,
    openCardStatus,
    cardStatus,
    availableCardsStatus,
  } = useSelector(cardSelector)

  const [timeRemaining, setTimeRemaining] = useState(cardsTimeLeft || null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalCard, setModalCard] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const interval = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cardActions.getUsersCards())
  }, [])

  useEffect(() => {
    if (availableCards === 0) {
      interval.current = setInterval(() => {
        setTimeRemaining((prevState) => {
          const newTimeRemaining = prevState - 1000
          return newTimeRemaining
        })
      }, 1000)
    }
    return () => interval && clearInterval(interval)
  }, [availableCards])

  useEffect(() => {
    if (timeRemaining <= 0 && timeRemaining !== null) {
      clearInterval(interval.current)
      dispatch(incrementAvailableCardsByOne())
    }
  }, [timeRemaining])

  useEffect(() => {
    if (availableCardsStatus === LOADING_STATUSES.succeeded) {
      setTimeRemaining(cardsTimeLeft)
    }
  }, [availableCardsStatus])

  useEffect(() => {
    if (cardsTimeLeft) {
      setTimeRemaining(cardsTimeLeft)
    }
  }, [cardsTimeLeft])

  useEffect(() => {
    dispatch(cardActions.getUsersCards())
  }, [])

  const openCard = (card) => {
    setDisabled(true)
    setModalCard(card)
    setIsOpenModal(true)
    dispatch(userActions.addTotalCoins(card.coins))
    dispatch(cardActions.getUsersCardById(card._id))
  }

  const closeModal = () => {
    setDisabled(false)
    setModalCard(null)
    setIsOpenModal(false)
  }

  return cardStatus === LOADING_STATUSES.succeeded ? (
    <>
      <S.CardsInfo>
        <S.CardsQuantity>
          Available cards:
          <S.CardsQuantityNumber>{availableCards}</S.CardsQuantityNumber>
        </S.CardsQuantity>
        <S.Grid>
          {cards.map((card) => (
            <Card
              key={card._id}
              card={card}
              disabled={disabled}
              availableCards={availableCards}
              openCardStatus={openCardStatus}
              timeRemaining={timeRemaining}
              openCard={openCard}
            />
          ))}
        </S.Grid>
      </S.CardsInfo>
      {isOpenModal && (
        <CardPopup
          isOpen={isOpenModal}
          card={modalCard}
          closeModal={closeModal}
        />
      )}
    </>
  ) : (
    <CardSkeleton />
  )
}

export default memo(CardGrid)

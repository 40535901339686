import { GIF } from 'assets/gifs'
import CoinImage from 'common/CoinImage'
import { Dialog } from 'common/Dialog'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function GameCongratulationsDialog({
  success,
  isOpen,
  closeDialog,
  reward,
}) {
  return (
    <Dialog closeDialog={closeDialog} isOpen={isOpen}>
      <S.CongratulationsWrapper>
        <S.CongratulationsTop>
          <S.StyledImage
            width={64}
            height={64}
            src={success ? GIF.correct : GIF.wrong}
            alt='Congratulations'
          />
          <S.CongratulationsTitle>
            {success ? 'Congratulations!' : 'Not this time'}
          </S.CongratulationsTitle>
        </S.CongratulationsTop>
        <S.RewardInfo>
          {success && (
            <S.RewardTitle>
              You have received: <CoinImage size='16' />{' '}
              {formatCoin(reward ?? 0)}
            </S.RewardTitle>
          )}
        </S.RewardInfo>
      </S.CongratulationsWrapper>
    </Dialog>
  )
}

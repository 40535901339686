import { USER_STATUSES } from './constants'

export const getAvailableCardsCount = (cards = []) => {
  if (!cards) {
    return 0
  }

  return cards.reduce((acc, val) => {
    if (val.timeLeft === 0) {
      return (acc += 1)
    }

    return acc
  }, 0)
}

export const getUserStatus = (cardsCount) => {
  switch (true) {
    case cardsCount > 0 && cardsCount < 20:
      return USER_STATUSES.newCommerce
    case cardsCount >= 20 && cardsCount < 30:
      return USER_STATUSES.tourist
    case cardsCount >= 30 && cardsCount < 40:
      return USER_STATUSES.steward
    case cardsCount >= 40:
      return USER_STATUSES.pilot
    default:
      return USER_STATUSES.newCommerce
  }
}

export const getCardLabel = (count) => `${count > 1 ? 'Cards' : 'Card'}`

import React from 'react'
import StyledButton from './styled'
import { COLORS } from '../../utils/constants'

// TODO Use children instead of "leftIcon text rightIcon"
function Button({
  text,
  onClick,
  isDisabled = false,
  isLoading = false,
  isOutline = false,
  maxWidth,
  maxHeight,
  iconColor = COLORS.white,
  textColor = COLORS.white,
  bgColor = COLORS.blue_00,
  borderColor = COLORS.blue_00,
  fontSize,
  leftIcon = null,
  rightIcon = null,
  ...rest
}) {
  return (
    <StyledButton
      onClick={onClick}
      disabled={isDisabled || isLoading}
      isOutline={isOutline}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      fontSize={fontSize}
      textColor={textColor}
      iconColor={iconColor}
      bgColor={bgColor}
      borderColor={borderColor}
      isLoading={isLoading}
      {...rest}
    >
      {leftIcon && leftIcon}
      {text}
      {rightIcon && rightIcon}
    </StyledButton>
  )
}

export default Button

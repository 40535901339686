import { css } from 'styled-components'

export const overflowScroll = css`
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`

import wrong from './wrong.gif'
import correct from './correct.gif'
import santa from './santa.gif'
import gift from './gift.gif'
import chickenGlasses from './chicken_glasses.gif'

export const GIF = {
  wrong,
  correct,
  santa,
  gift,
  chickenGlasses,
}

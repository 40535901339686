import CoinIcon from 'assets/icons/CoinIcon'
import Button from 'common/Button'
import FormInput from 'common/FormInput'
import SingleText from 'common/Input'
import TaskIcon from 'common/TaskIcon'
import { useMemo } from 'react'
import { getCardLabel } from 'utils/card'
import {
  CONFIRMATION_TASKS,
  TASK_SOURCES,
  TASK_SPONSORS,
} from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import { getTaskPopupAction, getTaskPopupCheck } from 'utils/taskText'

import * as S from './styled'

export function CheckTask({ task, onSubscribe, onCheck, value, setValue }) {
  const taskIcon = useMemo(() => {
    return task.source === TASK_SOURCES.sponsor
      ? task.sponsor
      : task.source ?? TASK_SOURCES.telegram
  }, [task.source, task.sponsor])

  return (
    <S.CheckTaskContent>
      <TaskIcon icon={taskIcon} size='120px' />
      <SingleText fontSize='16px' fontWeight='500'>
        {task.description}
      </SingleText>
      <S.TaskPoints>
        <S.TaskCardInvitationRewardCard>
          {task.cardsCount} {getCardLabel(task.cardsCount)} and
        </S.TaskCardInvitationRewardCard>
        <CoinIcon />
        <SingleText fontSize='14px' fontWeight='600'>
          {formatCoin(task.reward)}
        </SingleText>
      </S.TaskPoints>
      <S.ButtonsWrapper>
        <Button
          onClick={() => onSubscribe(task)}
          text={getTaskPopupAction(task)}
          maxWidth='100%'
        />
        {CONFIRMATION_TASKS.includes(task.sponsor) && (
          <>
            <S.OrDivider>OR</S.OrDivider>
            <FormInput
              placeholder={`${
                task.sponsor === TASK_SPONSORS.okx ? 'OKX' : ''
              } ID`}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </>
        )}
        <Button
          onClick={() => onCheck(task)}
          text={getTaskPopupCheck(task)}
          bgColor='transparent'
          maxWidth='100%'
        />
      </S.ButtonsWrapper>
    </S.CheckTaskContent>
  )
}
